<template>
    <div class="ml-2 mr-2">
        <div class="h-100 ">
            <div class="d-flex flex-row flex-wrap justify-content-between align-items-center">
                <div class="breadcrumbactive">
                    <div>
                        <span class="pointer" @click="goToListView">Organization</span>
                        <div>Link Employee</div>
                    </div>
                </div>
            </div>
            <div class="start-overflow mr-2">
                <div class="main-div create-company-container w-100">
                    <div class="main-div">
                        <div class="row mb-4">
                            <div class="col-lg-12 px-0">
                                <div class="px-0 mb-0">
                                    <div class="main-div mr-4">
                                        <div class="create-company-header d-flex align-items-center justify-content-between" style="border-radius: 0 6px 0 0;">
                                            <span class="fs-14 text-white">Link Employee</span>
                                        </div>
                                    </div>
                                    <!-- <div class="text-center fs-14 fw-600" style="color: #003cb9">
                                        Link employee
                                    </div> -->
                                    <div class="d-flex  w-100 mt-6 ml-8">
                                        <span class="d-flex p-2 link-employee" :class="{'basic-information-sucess' : permissionProduct}">
                                            <div class="row">
                                                <div v-if="!permissionProduct" class="col-3">
                                                    <img src="../../../../public/static/images/Group 40367.svg" width="40px">
                                                </div>
                                                <div v-if="permissionProduct" class="col-3">
                                                    <img src="../../../../public/static/images/Group 40365.svg" width="40px">
                                                </div>
                                                <div class="col-9">
                                                    <div v-if="permissionProduct" class="fs-12" style="color: #fff;">Step 1:</div>
                                                    <div v-if="!permissionProduct" class="fs-12" style="color: #237c9d;">Step 1:</div>
                                                    <div v-if="permissionProduct" class="fs-14 fw-600" style="color: #fff;">Basic Information</div>
                                                    <div v-if="!permissionProduct" class="fs-14 fw-600" style="color: #237c9d;">Basic Information</div>
                                                </div>
                                            </div>
                                            
                                        </span>
                                        <span v-if="!permissionProduct" class="p-2 d-flex">
                                            <div class="row">
                                                <div class="" style="margin-left: 40px !important;">
                                                    <div class="fs-12" style="color: #acacac;">Step 2:</div>
                                                    <div class="fs-14 fw-600" style="color: #acacac;">Product Permission</div>
                                                </div>
                                            </div>
                                            
                                        </span>
                                        <span v-if="permissionProduct" class="p-2 link-employee ml-3 d-flex">
                                            <div class="row">
                                                <div v-if="permissionProduct" class="col-3">
                                                    <img src="../../../../public/static/images/Group 40367.svg" width="40px">
                                                </div>
                                                <div class="col-9">
                                                    <div v-if="permissionProduct" class="fs-12" style="color: #237c9d;">Step 2:</div>
                                                    <div v-if="permissionProduct" class="fs-14 fw-600" style="color: #237c9d;">Product Permission</div>
                                                </div>
                                            </div>
                                            
                                        </span>
                                        <!-- <span :class="basicInfo ? 'selectedCircle' : permissionProduct  ? 'finishedCircle' : 'unfinishedCircle'"
                                            class="mx-5">
                                            1
                                            <span :class="basicInfo ? 'finishedCircleText' : 'unfinishedCircleText'">
                                                Basic Info
                                            </span>
                                        </span> -->
                                        <!-- <span class="unfinishedDash"></span>
                                        <span
                                            :class="permissionProduct ? 'selectedCircle' :  'unfinishedCircle'"
                                            class="mx-5">
                                            2
                                            <span :class="permissionProduct ? 'finishedCircleText' : 'unfinishedCircleText'">
                                                Products Permission 
                                            </span>
                                        </span>  -->
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div :class="{'d-none':!basicInfo}">
                            <basic-emp-info @switchWizard="switchWizard" ></basic-emp-info>
                        </div>
                        <div v-if="permissionProduct">
                            <link-product-permission @switchWizard1="switchWizard1" ></link-product-permission>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        
    </div>
    
</template>

<script>
    import LinkProductPermission  from "./LinkProductPermission.vue";
    import { EventBus } from '../../eventBus/nav-bar-event';
    import BasicEmpInfo from "./BasicEmpInfo.vue";
    export default {
        mixins: [],
        data() {
            return {
                basicInfo:true,
                basicInformationTab: false,
                permissionProductTab : false,
                permissionProduct:false,
                comp_id:''
            }
        },
        // beforeRouteLeave (to, from, next) {
        //     // if (this.edit_enable == true) {
        //         let message = "<div class='d-block'>Unsaved Basic Information Data would be lost, Do you want to proceed?</div>";
        //         // <img src='/static/images/exclamation-yellow.svg' class='d-block h-100px mx-auto mb-5'/><br>
        //         let options = {
        //             html: true,
        //             loader: false,
        //             reverse: false, // switch the button positions (left to right, and vise versa)
        //             okText: 'Yes',
        //             cancelText: 'No',
        //             animation: 'fade',
        //             type: 'basic',
        //             verification: 'continue', // for hard confirm, user will be prompted to type this to enable the proceed button
        //             verificationHelp: 'Type "[+:verification]" below to confirm', // Verification help text. [+:verification] will be matched with 'options.verification' (i.e 'Type "continue" below to confirm')
        //             backdropClose: true,
        //         };
        //         this.$dialog.confirm(message, options)
        //         .then(function () {
        //             next();
        //         })
        //         .catch(function () {
        //             next(false);
        //         });
        //     // } else {
        //     //     next()
        //     // }
        // },
        methods: {
            //Redirect tot the list view of teh organisation
            goToListView() {
                this.$router.push('/organization/listview');
            },
            //Switch the wizards between permission product and product info
            switchWizard(){
                setTimeout(() => {
                    this.permissionProduct = true
                    this.basicInfo = false
                    this.permissionProductTab = true
                    this.basicInformationTab = false
                }, 1000);  
            },
            //Switch the wizards between permission product and product info
            switchWizard1(){
                this.permissionProduct = false
                this.basicInfo = true
                this.permissionProductTab = false
                this.basicInformationTab = true
            }
        },
        mounted() {
            if(this.loggedInUser.has_organization_or_standalone) {
                if(this.loggedInUser.user_role == 'employee') {
                    this.$router.push('/company/listview');
                }
            } else {
                this.$router.push('/company/createcompanyororganization');
            }
            
        },
        computed: {
            loggedInUser() {
                return this.$store.state.loggedInUser;
            }
        },
        components: {
            LinkProductPermission,
            BasicEmpInfo
        },
    }
</script>
<style>
    .main-div {
        background: #fff;
        /* border-top-right-radius: 10px;
        border-top-left-radius: 10px;
        border-bottom-right-radius: 10px;
        border-bottom-left-radius: 10px; */
        padding-top: 0px !important;
        padding-bottom: 10px;
    }

    .create-company-container {
        background-color: #fff;
        /* padding: 15.5px 0 0px 0; */
        /* border-radius: 6px; */
    }
    .overflow-auto {
        overflow: auto;
    }
    .unfinishedCircle {
        background: #e8e8e8;
        border-radius: 50%;
        height: 50px;
        width: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: rgba(0, 0, 0, 0.32);
        font-weight: 500;
    }

    .finishedCircle {
        background: #128807;
        border-radius: 50%;
        height: 50px;
        width: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #ffffff;
        font-weight: 500;
    }

    .selectedCircle {
        background: #ff9a06;
        border-radius: 50%;
        height: 50px;
        width: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #ffffff;
        font-weight: 500;
    }

    .finishedCircleText {
        position: absolute;
        font-size: 14px;
        color: #303031;
        margin-top: 6em;
        font-weight: 600;
    }

    .unfinishedCircleText {
        position: absolute;
        font-size: 14px;
        color: rgba(0, 0, 0, 0.32);
        margin-top: 6em;
        font-weight: 600;
    }

    .unfinishedDash {
        color: #e8e8e8;
        font-size: 20px;
        border-top: 2px dashed rgba(0, 0, 0, 0.32);
        width: 25%
    }

    .finishedDash {
        color: #128807;
        font-size: 20px;
        border: 1px dashed #128807;
        width: 100px;
    }

    .mx-input {
        display: inline-block;
        height: 0px !important;
    }
    .main-div {
        margin-left: 7px;
    }
    .link-employee{
        border: 1px solid #128807;
        width: 215px;
        border-radius: 5px;
        background-color: #acd7e2;
    }
    .basic-information-sucess{
        background-color: #058125;
    }
</style>